.org-detail-list{ 
    display: inline;
}

.org-detail-item {
    float: left;
    width: 45%;
    color: rgba(0, 0, 0, 0.85);
    padding: 10px; 
}
.org-detail .org-detail-other .org-detail-list:nth-last-child(2) .org-detail-item, 
.org-detail .org-detail-other .org-detail-list:last-child .org-detail-item{
    width: 100%;
}
.org-detail-other .org-detail-list .org-detail-item img,
.org-orginfo-form-base .org-detail-item img {
    max-width: 120px;
    max-height: 120px;
}
.org-orginfo-form-base {
    width: 100%;
}
.org-orginfo-form-base .org-detail-list {
    float: left;
    width: 70%; 
}
.org-orginfo-form-base .org-detail-list:first-of-type {
    float: right;
    width: 25%;  
}
.org-detail-item.feeUrl .urlname {
    width: 120px;
    padding: 8px 0;
    text-align: center;
}
.org-detail-item.feeUrl img{
    width: 120px;
    height: 120px;
}
.org-box-detail .org-detail-other::after {
    clear: both;
    contain: "";
    display: block;
}
.org-box-detail .org-main {
    overflow: hidden;
    border: none;
    box-shadow: none;
}