.home {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #fff url(../../img/bg.jpg) no-repeat top center;
    background-size: 100% 100%;
}

.home .home-header {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 80px;
    padding: 16px 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}

.home-header .logo {
    background: url(../../img/logo.png);
    height: 48px;
    width: 48px;
    float: left;
}

.home-header .title {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 26px;
    line-height: 48px;
    font-family: SourceHanSansSC-regular;
}

