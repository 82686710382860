 .partyFeeMangement .formItem-columus3 {
     width: 33%;
     float: left;
 }

 .partyFeeRecord .formItem-columus3 {
     width: 25%;
     float: left;
 }

 .partyFeeMangement .formItem-columus2 {
     width: 50%;
     float: left;
 }

 .partyFeeMangement form.forms-columns1::after {
     clear: both;
     display: block;
     content: "";
 }

 .baseChanging-item {
     display: inline;
     margin: 8px;
 }

 .baseChanging-list>div {
     margin: 8px 0;
 }

 .baseChanging-time {
     font-weight: bold;
 }

 .partyFeeRecord form .formItem-columus3 .ant-form-item-control-wrapper {
     text-align: right;
 }

 .partyFeeRecord form .ant-form-item .ant-form-item-children img {
     max-width: 120px;
     max-height: 120px;
 }

 .partyFeeOthers .formItem-columus3 {
     width: 33%;
     float: left;
 }

 .partyFeeRecord .formItem-columus3 {
     width: 25%;
     float: left;
 }

 .partyFeeOthers .formItem-columus2 {
     width: 50%;
     float: left;
 }

 .partyFeeOthers form.forms-columns1::after {
     clear: both;
     display: block;
     content: "";
 }

 .baseChanging-item {
     display: inline;
     margin: 8px;
 }

 .baseChanging-list>div {
     margin: 8px 0;
 }

 .baseChanging-time {
     font-weight: bold;
 }

 .partyFeeRecord form .formItem-columus3 .ant-form-item-control-wrapper {
     text-align: right;
 }

 .partyFeeRecord form .ant-form-item .ant-form-item-children img {
     width: 120px;
     height: 120px;
 }

 .pvModal {
     display: flex;
     align-items: center;
     justify-content: center;
 }

 .pvModal img {
     max-width: 100%;
     max-height: 552px;
 }

 .partyFeeBaseChanging-item {
     max-height: 300px;
     overflow-y: auto;
 }

 .partyFeeBaseChanging-item .baseChanging-list-ul {
     border-bottom: solid 1px #ddd;
 }