.dashboard-page {
    position: absolute;
    top: 80px;
    width: 100%;
    bottom: 0;
}

.dashboard-cont {
    padding: 40px 80px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
    transform-origin: center top;
}

.dashboard-inner {
    width: 1600px;
    max-width: 100%;
    min-width: 900px;
    /* min-height: 400px; */
    max-height: 100%;
    margin: 0 auto;
    transform-origin: center top;
}

.dashboard-table-head .tr::after, .dashboard-table-body .tr::after {
    display: table;
    content: "";
    clear: both;
}

.dashboard-table-body .tr, .dashboard-table-head .tr {
    display: flex;
    flex-direction: row;
}

.dashboard-table-head .th, .dashboard-table-body .td {
    flex: 1;
    box-sizing: border-box;
}

.dashboard-table-head .th {
    padding-left: 10px;
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font: 500 20px/60px "microsoft Yahei";
}

.dashboard-table-body .td {
    padding: 0 12px 0 10px;
    height: 72px;
    overflow: visible;
}

.dashboard-table-body .td .cell {
    height: 60px;
    background: rgb(0, 0, 0, .4);
    padding: 17px 10px;
    font: 500 16px/26px "微软雅黑";
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.dashboard-table-body .td .cell:hover {
    background-color: rgb(0, 0, 0, .25);
}

/* .dashboard-table-body .td .cell:focus {
    background-color: rgb(0, 0, 0, .55);
} */

.dashboard-table-body .td .cell .anticon {
    font: 500 24px/26px "微软雅黑";
    margin-right: 10px;
    float: left;
    transition: -webkit-transform .3s linear;
    transition: transform .3s linear;
    transition: transform .3s linear, -webkit-transform .3s linear;
}

.dashboard-table-body .td .cell::after {
    display: table;
    clear: both;
    content: "";
}

.dashboard-table-body .td .cell:hover .anticon {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
}

.dashboard-page .ant-spin-nested-loading, .dashboard-page .ant-spin-container {
    height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0;
}