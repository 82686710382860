.democratic-review .ant-tabs-bar .ant-tabs-tab {
    font-size: 16px;
    color: rgba(0, 0, 0, .65);
    padding: 10px 16px;
}

.democratic-review .ant-tabs-bar .ant-tabs-tab-active {
    color: #D75959;
}

.itemBank-modal .ibm-part {
    margin-bottom: 15px;
}

.ibm-part h2 {
    font: 500 14px/14px "microsoft Yahei";
    padding-left: 10px;
    color: rgba(0, 0, 0, .85);
    border-left: solid 2px #D75959;
    margin: 15px 0;
}

.itemBank-modal .ant-empty-normal {
    margin: 0;
}

.ibm-part .sp-bars {
    padding: 0 0 15px 0;
}

.smb-list ul li {
    line-height: 25px;
    height: 25px;
    padding:0 0 30px 0;
}

.smb-list ul::after {
    display: table;
    content: "";
    clear: both;
}