.party-activ-page .party-activ-bars .party-activ-forms {
    flex: auto;
    text-align: right;
}

.party-activ-bars {
    display: flex;
    flex-direction: row;
}

.party-activ-btns {
    width: auto;
    line-height: 40px;
}

.party-activ-forms .formItem-columus1 .ant-form-item-control-wrapper .ant-select {
    min-width: 100px;
}

.party-activ-forms .formItem-columus2 .ant-form-item-control-wrapper .ant-select {
    min-width: 140px;
}

.party-activ-forms .formItem-columus3 .ant-form-item-control-wrapper .ant-calendar-picker {
    min-width: 260px;
}

.party-activ-content {
    padding-top: 15px;
}

.party-activ-content .self-right-menu {
    background: #fff;
    position: absolute;
    border: 1px solid rgba(204, 204, 204, 0.719);
    z-index: 999999;
    border-radius: 5px;
    padding: 0;
    list-style: none;
}

.party-activ-content .self-right-menu li:hover {
    background: rgba(51, 153, 204, 0.2);
}

.party-activ-content .self-right-menu li {
    width: 90px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    padding-left: 15px;
}

.panew-marin-tipinfo {
    margin: 24px 0 16px 0;
    clear: both;
}

.panew-marin-tipinfo .panew-main-tip {
    width: 3px;
    height: 14px;
    line-height: 20px;
    background-color: rgba(215, 89, 89, 1);
    text-align: center;
    margin: 3px 8px 3px 0;
    float: left;
}

.panew-form {
    margin: 0 50px;
}

.padetail-detail {
    margin: 10px 50px;
    clear: both;
}

.padetail-detail-item-file {
    float: left;
    width: 100%;
    color: rgba(0, 0, 0, 0.85);
    padding: 10px;
}

.padetail-detail-item-base {
    float: left;
    width: 33%;
    color: rgba(0, 0, 0, 0.85);
    padding: 10px;
}

.panew-footer-button {
    clear: both;
    text-align: right;
    padding-right: 50px;
}

.party-activ-sign {
    flex: auto;
    text-align: right;
    line-height: 40px;
}

.party-activ-sign span.ant-input-group-wrapper {
    vertical-align: middle;
}

.partysign-activ-page .party-activ-bars, .partysign-activ-page .partysign-list {
    margin: 0 80px;
}

.partysign-list {
    /* border: 1px solid #ddd; */
    margin: 10px 0;
}

.partysign-list ul li {
    min-height: 125px;
    float: left;
}

.partysign-list ul::after {
    clear: both;
    content: "";
    display: block;
}

.sign-list-border {
    width: 284px;
    height: 94px;
    line-height: 20px;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 16px 32px;
    float: left;
    z-index: 11;
}

.sign-list-border .sign-list-child {
    position: absolute;
    width: 400px;
    z-index: 1;
    background: white;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.sign-list-child .sign-info {
    margin: 16px;
    text-align: left;
}

/* .sign-list-border .sign-list-child:hover { 
    display: block;
} */

.sign-list-border .sign-inner-pic {
    /* display: flex; */
    height: 42px;
    line-height: 42px;
    margin: 16px 16px 4px 16px;
}

.sign-info .sign-inner-pic {
    /* display: flex; */
    height: 32px;
    line-height: 32px;
    margin: 8px;
}

.sign-list-border .sign-inner-pic .pic-img {
    float: left;
}

.sign-list-border .sign-date {
    float: right;
    margin-right: 16px;
}

.sign-list-border .sign-inner-pic .pic-img img {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 8px;
}

.sign-info .sign-inner-pic .pic-img img {
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    margin-right: 8px;
}

.sign-com li {
    min-height: auto !important;
    margin: 8px;
}

.sign-list-border .sign-inner-pic .pic-img .pic-name {
    margin-left: 50px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    text-align: left;
    float: none!important;
    width: 200px;
}

.sign-list-border .sign-inner-pic .sign-inner-in {
    float: right;
    color: red;
}
.sign-list-border .sign-inner-pic .sign-inner-time {
    float: right; 
}

.panew-form form .formItem-columus4 {
    width: 25% !important;
}

.panew-form.newForms form .formItem-columus1:last-child .ant-form-item-control ul.ant-select-selection__rendered {
    height: 100px;
}

.padetail-form .meetingDesctrib {
    display: -webkit-inline-box;
}

.padetail-button {
    float: right;
}

.padetail-button button {
    margin-left: 8px;
}

.panew-form form .ant-form-item .ant-calendar-picker {
    width: 100% !important;
}
.padetail-detail-list img {
    max-width: 100%;
}