.userNav {
    width: 160px;
    position: fixed;
    z-index: 99999;
    top: 60px;
    padding-top: 20px;
    right: 2px;
}

.userNav .userNav-inner {
    display: block;
    border-radius: 0 0 4px 4px;
    background: rgb(0, 0, 0, .3);
    border: solid 1px rgb(0, 0, 0, .3);
    box-shadow: 0 0px 10px #aaa;
}

.userNav-menu {
    padding: 10px;
}

.userNav-menu ul li {
    width: 168px!important;
    float: none!important;
    font: 500 14px/45px "microsoft Yahei"!important;
    height: 45px!important;
    color: #ddd!important;
    cursor: initial!important;
}

.userNav-menu ul li.is-menu a {
    padding-left: 10px!important;
    color: #fff!important;
    cursor: pointer!important;
    display: block;
}