.partyMemberDevelopment .ant-tabs-bar .ant-tabs-tab {
    font-size: 16px;
    color: rgba(0, 0, 0, .65);
    padding: 10px 16px;
}

.partyMemberDevelopment .ant-tabs-bar .ant-tabs-tab-active {
    color: #D75959;
}

.party-applicat .forms-columns2 .formItem-columus1 {
    width: 100%;
    clear: both;
}

.party-applicat .forms-columns2 .ant-form-item {
    width: 50%;
    float: left;
}

.party-applicat .forms-columns2 .ant-form-item.formItem-columus1 {
    width: 100%;
    float: left;
}

/* .party-applicat .forms-columns2 .ant-form-item.formItem-columus1 span input {
    min-height: 80px;
} */

.party-applicat .ant-form::after {
    clear: both;
    content: "";
    display: table;
}

.party-applicat .forms-columns2 .ant-upload-select-picture-card .ant-upload img {
    height: 142px;
}

.partyNumberInfo ul {
    margin: 0;
    padding: 0;
    height: 60px;
    float: left;
}

.partyNumberInfo ul li {
    float: left;
    line-height: 60px;
    padding: 0 20px;
    font-size: 16px;
    color: rgba(0, 0, 0, .65);
    cursor: pointer;
}

.partyNumberInfo ul li.active {
    color: rgba(215, 89, 89, 1);
    border-bottom: solid 2px #D75959;
}

.partyNumberInfo .partyNumberInfo-count {
    float: right;
}

.partyNumberInfo-count .member-number {
    width: 190px;
    float: left;
    height: 45px;
    border-radius: 4px;
    background-color: rgba(215, 89, 89, 1);
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.09);
    color: #fff;
    line-height: 45px;
    margin-left: 10px;
}

.partyNumberInfo-count .member-number:first-child {
    width: 220px;
}

.partyNumberInfo::after {
    display: table;
    content: "";
    clear: both;
}

/* .partyNumberInfo .member-number {} */

.member-number .edit {
    float: right;
    margin: 16px 8px 0 0;
    line-height: 25px;
}

.partyNumberInfo-search {
    display: flex;
    flex-direction: row;
    padding: 15px 0;
}

.partyNumberInfo-search .sp-forms {
    flex: auto;
    text-align: right;
}

.partyNumberInfo-search .sp-btns {
    display: -webkit-box;
}

/* 
.partyNumberInfo-search::after {
    display: block;
    content: "";
    clear: both;
}
.partyNumberInfo-search .sp-btns button {
    margin-right: 8px;
} */

.party-applicat form .formItem-columus1 .ant-col-sm-3 {
    width: 16.5%;
}

.party-applicat form .formItem-columus1 .ant-col-sm-21 {
    width: 83.5%;
}