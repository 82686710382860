.simple-page .sp-bars {
    display: flex;
    flex-direction: row;
}

.simple-page .sp-bars .sp-btns {
    width: auto;
}

.simple-page .sp-bars .sp-forms {
    flex: auto;
    text-align: right;
}

.simple-page .sp-bars .sp-btns .ant-btn {
    margin-right: 10px;
}

.simple-page .sp-bars::after {
    display: table;
    content: "";
    clear: both;
}

.simple-page .sp-content {
    padding-top: 10px;
}