 .login-background {
     position: fixed;
     height: 100%;
     width: 100%;
     background: #88000a url(../../img/bg.jpg) no-repeat top center;
     background-size: 100% 100%;
     z-index: 0;
 }

 .login-bgOpcity {
     position: absolute;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.25);
 }

 .login-box {
     position: absolute;
     width: 100%;
     top: 50%;
     margin-top: -240px;
     z-index: 99999;
     text-align: center
 }

 .login-logo {
     height: 77px;
     text-align: center;
 }

 .login-logo img {
     vertical-align: middle;
 }

 .login-title {
     color: rgba(255, 255, 255, 1);
     font-size: 28px;
     text-align: center;
     font-family: SourceHanSansSC-bold;
     margin: 0 auto 24px auto;
 }

 .login-form {
     position: absolute;
     top: 100%;
     width: 300px;
     left: 50%;
     margin-left: -150px;
 }

 .login-form .ant-form-item {
     padding-bottom: 24px;
     height: 72px;
     margin: 0;
 }

 .login-form .ant-form-item .ant-form-explain {
     height: 24px;
     color: rgba(255, 152, 0, 1);
     font-size: 14px;
     text-align: left;
     padding-left: 15px;
     line-height: 24px;
 }

 .login-form input {
     height: 48px;
     line-height: 22px;
     border-radius: 26px;
     padding: 13px 15px;
     color: rgba(0, 0, 0, 0.6);
     font-size: 14px;
     font: 500 14px/22px "MicrosoftYaHei";
 }

 .login-remberpwd {
     display: inline-block;
     width: 100%;
     padding: 0 10px 25px 10px;
 }

 .login-remberpwd .pic {
     display: block;
     float: left;
 }

 .login-remberpwd .pic img {
     vertical-align: sub;
 }

 .login-rem {
     color: rgba(255, 255, 255, 1) !important;
     font-size: 14px;
     text-align: left;
     display: inline-block;
     margin-left: -100px;
 }

 .login-supp {
     color: #fff !important;
     font-size: 14px;
     text-align: left;
     display: block;
     float: right;
     margin-right: 10px;
     cursor: pointer;
 }

 .login-supp:hover {
     color: #fff !important;
 }

 .login-submit-button {
     width: 297px;
     height: 48px;
     line-height: 23px;
     border-radius: 26px;
     background-color: rgba(255, 152, 0, 1) !important;
     border-color: rgba(255, 152, 0, 1) !important;
     color: rgba(255, 255, 255, 1) !important;
     font-size: 16px;
     text-align: center;
     font-family: Roboto;
     margin-top: 16px;
 }

 .login-form .ant-checkbox-checked .ant-checkbox-inner {
     background-color: #FF9800 !important;
     border-color: #FF9800 !important;
 }

 .login-form .ant-btn-primary:hover, .login-form .ant-btn-primary:focus {
     color: #fff;
     background-color: rgba(255, 152, 0, .8) !important;
     border-color: rgba(255, 152, 0, .8) !important;
 }

 .login-footer {
     height: auto;
     color: rgba(255, 255, 255, 1) !important;
     text-align: center;
     font-family: SourceHanSansSC-regular;
     position: absolute;
     bottom: 10px;
     padding: 0;
     left: 0;
     right: 0;
 }

 .login-footer p {
     line-height: 20px;
     padding: 0;
     margin: 0;
 }

 .login-footer a {
     color: rgba(255, 255, 255, 1) !important;
 }

 .login-footer a:hover {
     color: rgb(255, 152, 0) !important;
 }

 .login-form .ant-form-item.do-action {
     height: 128px;
 }

 .login-form .ant-checkbox-disabled+span {
     color: inherit;
 }