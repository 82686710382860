.tree-right-menu{ 
    position: absolute;
    z-index: 99999;
    background: #fff;
    border: solid 1px #ddd
}
.tree-right-menu li { 
    list-style: none;
    padding: 0;
    margin: 0;
    font: 500 14px/33px "Microsoft Yahei"; 
    width: 90px;
    cursor: pointer;
    text-align: center;
}

.tree-right-menu li:hover{ 
    background: #fff2f0; 
}