.my-h1, .my-h2, .my-h3 {
    padding: 0 10px 5px;
    margin: 0;
    border-bottom: solid 1px #ddd;
    box-sizing: content-box;
    margin-bottom: 15px;
}

.my-h1 {
    font: 600 20px/50px "微软雅黑";
    height: 50px;
}

.my-h2 {
    font: 600 16px/40px "微软雅黑";
    height: 40px;
}

.my-h3 {
    font: 600 14px/30px "微软雅黑";
    height: 30px;
}

.my-h1 .anticon, .my-h2 .anticon, .my-h3 .anticon {
    margin-right: 8px;
    font-weight: normal;
}