* {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img, a {
  border: none;
}

.home-page {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #fff url(./img/bg.jpg) no-repeat top center;
  background-size: 100% 100%;
}

.home-cont {
  position: absolute;
  left: 80px;
  top: 60px;
  right: 80px;
  bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.home-inner {
  width: 1600px;
  max-width: 100%;
  max-height: 100%;
  height: 860px;
  margin: 0 auto;
  background: rgba(255, 255, 255, .6);
  box-shadow: 0 0 2px rgba(255, 255, 255, .6);
}

.home-table-head .tr::after, .home-table-body .tr::after {
  display: table;
  content: "";
  clear: both;
}

.home-table-head .th, .home-table-body .td {
  width: 20%;
  float: left;
  padding: 5px 10px;
  box-sizing: border-box;
  padding-right: 20px;
}

.bars {
  color: rgba(255, 255, 255, 1) !important;
  height: 48px;
  padding: 3px;
  line-height: 42px;
  float: right;
}

.home-header::after {
  display: table;
  content: "";
  clear: both;
}

.bars ul {
  display: block;
  padding: 0;
  margin: 0;
}

.bars ul::after, .bars ul li.user::after {
  display: table;
  content: "";
  clear: both;
}

.bars ul li {
  width: 47px;
  height: 42px;
  float: right;
  list-style: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.bars ul li a {
  display: block;
  color: #fff !important;
}

.bars ul li .anticon {
  font-size: 23px;
  line-height: 42px;
  top: 5px;
  position: relative;
}

.bars ul li .ant-badge-count {
  background: #F5222D;
  box-shadow: none;
  font-size: 13px;
}

.bars ul li.user {
  width: auto;
  float: right;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 42px;
}

.bars ul li.user .userNav {
  display: none;
}

.bars ul li.user:hover .userNav {
  display: block;
}

.bars ul li img {
  cursor: pointer;
}

.user .user-logo {
  height: 42px;
  width: 42px;
  float: left;
  display: block;
  margin-right: 9px;
  padding: 0;
  overflow: hidden;
}

.user .user-logo img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: none;
  position: relative;
  top: -2px;
}

.mainDiv .ant-card .ant-card-body {
  padding: 0;
  position: relative;
}

.mainDiv .ant-card .ant-card-body .tableAction {
  left: 0;
}

.mainDiv .ant-card .ant-card-body .SystemSetList {
  padding-top: 10px;
  margin: 0;
  clear: both;
}

.mainDiv .tableSearchForm .ant-form-item-control {
  width: 500px;
}

.mainDiv .tableSearchForm .ant-form-item-control .ant-input {
  width: 300px;
}

.mainDiv .tableSearchForm .ant-form-inline .ant-form-item {
  margin-right: 0;
  text-align: right;
}

.tableSearchForm .operation {
  width: auto;
  text-align: right;
  margin-left: 30px !important;
  vertical-align: middle;
  margin-top: 3px;
}

.tableSearchForm .ant-btn {
  margin-left: 10px !important;
}

.nav-panels .operationArea {
  text-align: center
}

.nav-panels .operationArea button {
  margin-left: 10px;
}

/* 重写滚动条样式 */

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #D75959;
}

.poc-main .page-404 {
  padding: 20px;
}

.poc-main .page-404 h1 {
  color: #ddd;
  font: 600 36px/45px "Microsoft YaHei";
}