.home-statistics {
    margin-top: 20px;
    height: 441px;
}

.home-statistics .item .inner {
    border-radius: 3px;
    box-shadow: 0px 0px 3px 0px #aaa;
    box-sizing: border-box;
}

.home-statistics .middle, .home-statistics .left {
    width: 33%;
    float: left;
}

.home-statistics .right {
    width: 33%;
    float: left;
    padding: 0;
}

.home-statistics::after {
    display: table;
    content: "";
    clear: both;
}

.home-statistics .item {
    padding-right: 10px;
    padding-left: 10px;
}

.inner .inner-content-item {
    padding-top: 20px;
    box-sizing: border-box;
    height: 392px;
}

/* .inner-content>div {
    height: 352px;
} */

.inner h3 {
    font: 500 16px/49px "微软雅黑";
    height: 49px;
    padding: 0 0 0 15px;
    color: rgba(0, 0, 0, 0.85);
    background-color: rgba(0, 0, 0, 0.02);
    margin: 0;
    text-align: center;
}

.orgG2 .inner-words {
    text-align: center;
    height: 200px;
    line-height: 200px;
}