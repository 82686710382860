.my-table {
    width: 100%;
    background: #fff;
    flex-direction: column;
    display: flex;
}

.my-table .my-sidebar {
    width: 18%;
    margin: 24px 32px 8px 24px;
    float: left;
}

.my-table .my-content {
    float: left;
    /* padding-left: 15px; */
    width: 78%;
    padding: 16px 16px 0px 0px;
}

.my-table .my-innercontent {
    border: 1px solid rgba(243, 243, 243, 1);
    padding: 16px 24px;
}

.my-title {
    border-bottom: solid 1px #ddd;
    font: 500 14px/20px "微软雅黑";
    margin-bottom: 15px;
    padding-bottom: 15px;
    color: rgba(0, 0, 0, 0.85);
}

.ant-tree {
    overflow: hidden;
}

.my-content {
    /* padding-left: 15px; */
}

.my-search {
    /* display: inline-flex; */
    margin-bottom: 8px;
}

.my-search .my-search-add, .my-search-type, .my-search .my-search-delete, .my-search .my-search-download {
    margin-right: 8px;
}

.my-content .my-listTable a:hover {
    text-decoration: underline
}

.tree-right-menu {
    padding: 0;
    border: solid 1px #ddd;
    border-radius: 4px;
    box-shadow: 0 0 3px #ddd;
    background: #fff;
}

.tree-right-menu li {
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0 20px;
}

.tree-right-menu li:hover {
    background: #fff2f0;
}

.self-right-menu {
    background: #fff;
    position: absolute;
    border: 1px solid rgba(204, 204, 204, 0.719);
    z-index: 999999;
    border-radius: 5px;
}

.self-right-menu li:hover {
    background: #fff2f0;
}

.self-right-menu li {
    width: 90px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    padding-left: 15px;
}

.my-search .my-search-name {
    display: inline-flex;
    line-height: 40px;
    width: 100%;
}

.my-search .my-search-form {
    width: 100%;
    float: right;
}

.my-search .my-search-name .ant-radio-group-outline {
    margin-right: 8px;
}

.my-search .my-search-new {
    margin-right: 0px;
    display: flex;
    float: right;
}

.my-search .my-search-new form {
    display: inline-flex;
}

.my-search-new .ant-form-inline .ant-form-item {
    margin-right: 8px;
}

.my-search-new .my-cascader {
    max-width: 110px;
}

.my-search-new .range-picker {
    max-width: 200px;
}

.my-model .ant-row {
    margin-bottom: 5px;
}

.my-top {
    display: flex;
}

.sidebar-bz {
    display: flex;
    color: rgba(0, 0, 0, 0.85);
    align-items: center;
}

.my-top .sidebar-bzbody {
    margin: 0px 8px;
    min-width: 100px;
}

.my-box {
    display: flex;
}

.my-tree {
    margin-top: 16px;
}

.my-tree li {
    line-height: 22px;
}

.files-list li .folder-li .img {
    display: block;
    height: 90px;
    margin: 20px 25px;
}

.files-list .files-list-li {
    height: 180px;
    text-align: center;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
}

.ul-border {
    margin: 15px;
    border: solid 1px #ddd;
}

.ul-border>div {
    padding: 10px;
    display: inline-grid;
    text-align: center;
    word-wrap: break-word;
}

.my-toggleButton {
    overflow: hidden;
    margin: 0px 15px;
    /* display: inline-flex; */
}

.my-toggleButton-add {
    float: left;
}

.my-toggleButton-right {
    float: right;
}

.ant-breadcrumb {
    height: 25px;
    margin: 20px 0;
}

.ant-breadcrumb a {
    color: #39c;
}

.my-toggleButton button, .my-toggleButton .ant-radio-group {
    margin: 0 0 0 6px;
}

.btn-container .anticon {
    /* float: left;
    line-height: 30px;
    margin-right: 5px;
    height: 30px;
    width: 30px; */
}

.btn-container .anticon:hover {
    border-radius: 2px;
    box-shadow: 0 0 5px #369;
    line-height: 30px;
    font-size: 24px;
    font-weight: 600;
}

.files-list li .btn-container {
    position: absolute;
    /* z-index: 10; */
    right: 0;
    top: 0;
    height: 30px;
    width: auto;
    /* display: none; */
}

.files-list li .select-container {
    position: absolute;
    /* z-index: 10; */
    left: 0;
    top: 0;
    height: 30px;
    width: auto;
    /* display: none; */
}

.files-list li .show-icon .btn-container {
    display: block;
}

.my-toggleView .ant-pagination {
    margin-right: 15px;
    float: right;
}

.my-toggle-line {
    background: #F3F3F3;
    height: 10px;
    margin: 15px 0 0 0;
    width: 100%;
}

.ant-modal-content .ant-form-horizontal .ant-input-number {
    width: 100%;
}

.selectRowStyl {
    background-color: #00b4ed73;
}