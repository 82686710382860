
.org-box {
    display: inline-flex;
    width: 100%;
    min-height: 600px;
}

.org-left {
    width: 300px;
    margin: 24px;
}

.org-box .inner-content{
    background: #fff;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    overflow: hidden;
}

.org-main {
    line-height: 20px;
    text-align: left;
    box-shadow: 0px 2px 8px 0px rgba(170, 170, 170, 1);
    border: 1px solid #ccc;
    margin: 16px 16px 16px 0;
    padding: 24px 32px;
    width: 100%;
    min-height: 100px;
    min-width: 100px;
}

.org-lab {
    height: 24px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    text-align: left;
    margin-bottom: 18px;
}

.org-treesel {
    display: inline-flex;
    margin: 16px 0 8px 0;
    width: 100%;
}

.org-button {
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0);
    margin-right: 8px;
}

.org-tree {
    /* border: 1px solid #ccc; */
    min-height: 100px;
}

.org-main-lab {
    margin-bottom: 24px;
    height: 28px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    text-align: left;
}

.org-marin-tipinfo {
    margin: 24px 0 16px 0;
    clear: both;
    font-size: 20px;
    padding-top: 10px;
}

.org-marin-tipinfo .org-main-tip {
    width: 3px;
    height: 14px;
    line-height: 20px;
    background-color: rgba(215, 89, 89, 1);
    text-align: center;
    margin: 3px 8px 3px 0;
    float: left;
}

/* .org-box .org-main-inner .ant-form.ant-form-horizontal {
    overflow: hidden;
} */
 
.org-orginfo-form-base .ant-form-item, .org-orginfo-form-leader .ant-form-item, .org-orginfo-form-other .ant-form-item {
    float: left;
    width: 50%;
}

.org-orginfo-form-other form .ant-form-item:nth-last-child(2), .org-orginfo-form-other form .ant-form-item:last-child {
    width: 100%;
}

.org-orginfo-form-other form .ant-form-item:nth-last-child(2) .ant-form-item-label, .org-orginfo-form-other form .ant-form-item:last-child .ant-form-item-label {
    width: 16.5%;
}

.org-orginfo-form-other form .ant-form-item:last-child .ant-form-item-control-wrapper {
    width: 83%;
}

/* .org-orginfo-form-base .ant-form-item:nth-last-child(1) {
    width: 60%;
} */

/* .org-orginfo-form-base .ant-form-item:nth-last-child(1) .ant-form-item-label {
    width: 22%;
} */

.has-icon .ant-form-item-children {
    display: flex;
    flex-direction: row;
    height: 40px;
    padding-top: 4px;
}

.has-icon .ant-form-item-children .ant-input {
    flex: auto;
}

.has-icon .ant-form-item-children .minus-circle {
    width: 32px;
    height: 32px;
    display: block;
    line-height: 40px;
    text-align: right;
    font-size: 20px;
    color: #BFBFBF;
}

.ul-border {
    list-style-type: none;
}

.folder-li {
    text-align: center;
    margin: 16px 16px 0 0;
}

.folder-li .img {
    width: 95px;
    height: 130px;
    border: none;
}

.org-box .avatar-uploader .ant-upload-select-picture-card .ant-upload img,
.org-box .org-orginfo-form-base .org-detail-item img {
    max-width: 120px;
    max-height: 120px;
}

.org-member-pic {
    overflow: hidden;
}

.org-member-pic .folder-li {
    text-align: center;
    margin: 16px 16px 0 0;
    float: left;
}
.org-box .org-main .org-orginfo-form-base .ant-calendar-picker {
    width: 100%;
}

.org-box .org-main-inner .ant-form-item-children .nodeSelect {
    width: 100%
}