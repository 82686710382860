.poc-main, .poc-main .ant-layout {
    height: 100%;
}

.poc-main .trigger {
    position: relative;
    top: 3px;
    font-size: 20px;
    line-height: 55px;
    padding: 0 10px;
    cursor: pointer;
    color: #fff;
    transition: all .3s;
    left: 30px;
    z-index: 999;
}

.poc-main .trigger.anticon-menu-unfold {
    margin-left: 0;
}

.poc-main .trigger.anticon-menu-fold {
    margin-left: 10px;
}

.poc-main .trigger:hover {
    color: #fff;
}

.poc-main .goHome {
    display: block;
    height: 55px;
    overflow: visible;
    cursor: pointer;
    position: relative;
    z-index: 1000;
}

.poc-main .logo {
    height: 55px;
    padding: 7px 4px 7px 3px;
}

.poc-main .logo img {
    width: 41px;
    height: 41px;
}

.poc-main .ant-layout-header {
    height: 55px;
    padding: 0;
}

.poc-main .system-name {
    position: absolute;
    width: 100%;
    max-width: 180px;
    height: 54px;
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    left: 48px;
    top: 0;
    display: block;
    font: 500 20px/54px "Microsoft Yahei";
    transition: all .3s;
    opacity: 1;
    z-index: 200;
}

.poc-main .ant-layout-sider {
    background-color: #101010 !important
}

.poc-main .ant-layout-sider.ant-layout-sider-collapsed .system-name {
    opacity: 0;
    display: none;
}

.poc-main .ant-menu-dark {
    background: #101010 !important;
}

.poc-main .ant-layout-sider.ant-layout-sider-collapsed li {
    margin-top: 0px;
}

.poc-main .ant-layout-sider.ant-layout-sider-collapsed .ant-menu-inline-collapsed {
    width: 48px !important
}

.poc-main .ant-menu-inline-collapsed>.ant-menu-item {
    padding-left: 0!important;
    padding-right: 0!important;
    width: 48px!important;
}

.poc-main .ant-menu-inline-collapsed .ant-menu-submenu-title {
    padding: 0!important;
}

.poc-main .ant-menu-inline-collapsed .ant-menu-submenu-title span {
    display: none!important;
    opacity: 0!important;
}

.poc-main section.ant-layout {
    background: #F5F5F5;
}

.poc-main section.ant-layout.content-layout {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.poc-main main.ant-layout-content {
    flex: auto;
    display: flex;
    flex-direction: column;
    background: #fefefe;
    position: relative;
}

.ant-layout-sider-collapsed {
    width: 48px !important;
    min-width: 48px !important;
    max-width: 48px !important;
    text-align: center;
}

.inner-content {
    /*  padding: 0 24px 24px 24px; */
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    padding: 16px;
}

.app-nav {
    float: right;
    padding: 3px 10px 4px 10px;
    z-index: 9999;
}

.app-nav .userNav {
    top: 35px;
}

.app-nav .userNav .userNav-inner {
    background: #fff;
    color: #333;
    border-color: #ddd;
}

.app-nav .userNav .userNav-menu ul li {
    width: 200px !important;
    float: none !important;
    font: 500 14px/45px "microsoft Yahei"!important;
    height: 45px!important;
    color: #bbb!important;
    cursor: initial!important;
}

.app-nav .userNav .userNav-menu ul li.is-menu a {
    padding-left: 10px!important;
    color: #333!important;
    cursor: pointer!important;
    display: block;
}

/* SiderIcon copy */

.SiderCustom {
    box-shadow: 0 10px 20px rgba(0, 21, 41, 0.35);
    z-index: 3;
    overflow-x: hidden;
    overflow-y: auto;
    left: 0;
}

.SiderCustom .spinWrapper {
    height: 100%;
}

.SiderCustom .SiderSpin {
    background: #364760;
    background: transparent;
}

.SiderCustom .SiderSearch {
    border-bottom: 1px solid hsla(0, 0%, 73.3%, 0.1);
}

.SiderCustom .SiderIcon {
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    height: 46px;
    line-height: 46px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.SiderCustom .SiderIcon .SiderIconTxt {
    font-size: 20px !important;
    color: rgba(255, 255, 255, 0.65);
    font-weight: bold;
    margin-right: 0;
}

.SiderCustom .SiderIcon .SiderIconTxt:hover {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
}

.SiderCustom .SiderIcon:hover {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
}

.SiderCustom .ant-spin-container {
    position: relative;
    transition: opacity 0s;
}

.SiderCustom .ant-spin-container::after {
    background: none;
    opacity: 0;
    transition: all 0s;
}

.SiderCustom .ant-input-search {
    display: block;
    padding: 8px 3px;
}

.SiderCustom .ant-input-search .ant-input {
    color: #b0bcda !important;
}

.SiderCustom .ant-input-search .anticon {
    color: #b0bcda !important;
}

.SiderCustom .ant-input-search input {
    height: 30px;
    background: transparent;
    font-family: MicrosoftYaHei;
    font-size: 9px;
    font-style: normal;
    border-radius: 8px;
    color: #b0bcda !important;
    border-color: rgba(255, 255, 255, 0.4) !important;
}

.SiderCustom .ant-input-search .ant-input::-webkit-input-placeholder {
    font-size: 9px;
    color: rgba(176, 188, 218, 0.6) !important;
}

.SiderCustom .ant-input-search .ant-input:-moz-placeholder {
    font-size: 9px;
    color: rgba(176, 188, 218, 0.6) !important;
}

.SiderCustom .ant-input-search .ant-input::-moz-placeholder {
    font-size: 9px;
    color: rgba(176, 188, 218, 0.6) !important;
}

.SiderCustom .ant-input-search .ant-input:-ms-input-placeholder {
    font-size: 9px;
    color: rgba(176, 188, 218, 0.6) !important;
}

.SiderCustom .ant-menu {
    background: #364760;
    border: none;
}

.SiderCustom .ant-menu .ant-menu-item .nav-text {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #b0bcda;
    letter-spacing: -0.44px;
}

.SiderCustom .ant-menu .ant-menu-item-selected {
    background-image: linear-gradient(-90deg, rgba(58, 155, 255, 0.82) 0%, #56e6ff 100%);
}

.SiderCustom .ant-menu .ant-menu-item-selected .nav-text {
    font-size: 13px;
    color: #fff !important;
    opacity: 1;
}

.SiderCustom .ant-menu .ant-menu-item-selected .anticon {
    color: #fff !important;
}

.SiderCustom .ant-menu .ant-menu-item>a {
    color: #b0bcda !important;
}

.SiderCustom .ant-menu .ant-menu-submenu-title {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #b0bcda;
    letter-spacing: -0.44px;
}

.SiderCustom .ant-menu .ant-menu-submenu-arrow:after {
    background: linear-gradient(to right, #b0bcda, #b0bcda);
}

.SiderCustom .ant-menu .ant-menu-submenu-arrow:before {
    background: linear-gradient(to right, #b0bcda, #b0bcda);
}

.SiderCustom .ant-menu .ant-menu-submenu-open .ant-menu-sub .ant-menu-item .nav-text {
    opacity: 0.9;
    font-family: MicrosoftYaHei;
    font-size: 13px;
    color: #b0bcda;
}

.SiderCustom .ant-menu .ant-menu-submenu-open .ant-menu-sub .ant-menu-item-selected {
    background-image: linear-gradient(-90deg, rgba(58, 155, 255, 0.82) 0%, #56e6ff 100%);
}

.SiderCustom .ant-menu .ant-menu-submenu-open .ant-menu-sub .ant-menu-item-selected .nav-text {
    font-size: 13px;
    color: #fff;
    opacity: 1;
}

.SiderCustom .ant-menu .ant-menu-submenu-open .ant-menu-sub .ant-menu-item-selected .anticon {
    color: #fff;
}

.SiderCustom .ant-menu .ant-menu-submenu-open .ant-menu-sub .ant-menu-item-selected:after {
    display: none;
}

.poc-main .nav-panels {
    display: flex;
    flex-direction: column;
    flex: auto;
}

.nav-panels .ReuseTabMenu-mask {
    position: fixed;
    z-index: 99998;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .1);
}

.nav-panels .ReuseTabMenu {
    position: fixed;
    z-index: 99999;
    padding: 0;
    border: solid 1px #ddd;
    border-radius: 4px;
    display: none;
    box-shadow: 0 0 3px #ddd;
}

.nav-panels .ReuseTabMenu .ant-menu-item {
    font: 14px/40px "Microsoft YaHei";
    height: 40px;
    margin: 0;
    padding: 0 20px;
}

.poc-main .nav-panels>.ant-tabs {
    display: flex;
    flex-direction: column;
    flex: auto;
}

/* .ant-tabs-card-content {
    overflow-y: scroll;
} */

.poc-main>.ant-layout-has-sider>.content-layout>.nav-panels>.ant-tabs>.ant-tabs-bar {
    margin: 0;
    background: #f5f5f5;
}

.poc-main .ant-tabs-content {
    flex: auto;
}

.poc-main .ant-tabs-content .ant-tabs-tabpane {
    display: flex;
    flex-direction: column;
}

.poc-main .table-logo {
    height: 40px;
    /* border: solid 1px #ddd; */
    cursor: pointer;
}

.poc-main .ant-table td a {
    cursor: pointer;
}

.poc-main .ant-select-dropdown-menu li, .poc-main .ant-select-tree li {
    text-align: left;
}

.ant-calendar .ant-calendar-ok-btn {
    background-color: #fff !important
}

.ant-calendar .ant-calendar-ok-btn:active, .ant-calendar .ant-calendar-ok-btn.active {
    background-color: #fff !important
}

.ant-input-search .anticon {
    color: #fff !important;
}